import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/zh_CN';

import { APP_NAME, APP_VERSION, APP_DESCRIPTION, APP_SHA } from 'services/constants';
import store from 'services/store';
import history from 'services/history';
import './styles.module.css';

const Main = loadable(() => import('scenes/Main'));

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <Router history={history}>
          <>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
              />
            </Helmet>
            <Switch>
              <Route path="/" component={Main} />
            </Switch>
          </>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
