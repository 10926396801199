import 'react-app-polyfill/stable';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import history from 'services/history';
import 'styles.css';
import './api.config';
import App from 'scenes/App';
import { unregister } from 'serviceWorker';

moment.locale('zh-cn');

// 对 sentry 上报的 URL 做脱敏处理，去除其中携带的 token 信息
const getDesensitizedUrl = (url) => {
  const [routePath, params] = (url || '').split('?');
  const paramsObj = new URLSearchParams(params);
  ['token', 'auth_token'].forEach((item) => {
    paramsObj.delete(item);
  });
  const paramsStr = paramsObj.toString();
  return paramsStr ? `${routePath}?${paramsStr}` : routePath;
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  enabled: process.env.NODE_ENV === 'production',
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_PERFORMANCE_SAMPLE_RATE) || 0.01,
  beforeSend(event) {
    const newEvent = {
      ...event,
      breadcrumbs: (event.breadcrumbs || []).map((breadcrumb) => {
        // 对 request 中的 url 作脱敏处理
        if (['xhr', 'fetch'].includes(breadcrumb.category)) {
          return {
            ...breadcrumb,
            data: { ...breadcrumb.data, url: getDesensitizedUrl(breadcrumb.data.url) },
          };
        }
        // 对路由中的 searchParams 作脱敏处理
        if (breadcrumb.category === 'navigation') {
          return {
            ...breadcrumb,
            data: {
              from: getDesensitizedUrl(breadcrumb.data.from),
              to: getDesensitizedUrl(breadcrumb.data.to),
            },
          };
        }
        return breadcrumb;
      }),
      tags: {
        ...event.tags,
        // 上报完整的脱敏后的页面 url
        url: getDesensitizedUrl(window.location.href),
      },
    };
    return newEvent;
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
