import { createReducer, fluxStandardActionHandleEnhancer } from 're-reducer';
import updateHelper from 'immutability-helper';
import { namespace, initialState } from './selectors';

const reducer = createReducer({
  namespace,
  initialState,
  handles: {
    update: fluxStandardActionHandleEnhancer((state, action) => {
      const { payload: results } = action;
      const nextState = updateHelper(state, {
        $merge: {
          results,
          pending: false,
        },
      });
      return nextState;
    }),
    updateTimeline: fluxStandardActionHandleEnhancer((state, action) => {
      const { payload: results } = action;
      const nextState = updateHelper(state, {
        $merge: {
          results: results.ids,
          timeline: results.results,
          pending: false,
        },
      });
      return nextState;
    }),
    pushHistory: (state, action) => {
      const { payload: shop } = action;
      const { histories } = state;
      const { user, ids } = shop;
      const index = histories.findIndex((item) => {
        if (item.type === 'array' && ids) {
          const index =
            item.type &&
            ids.findIndex((itemId) => {
              return item.ids.includes(itemId);
            });
          return index !== -1;
        }
        return item.user.uid === user.uid;
      });
      if (!ids && index > -1 && index < 10) {
        return state;
      }
      const nextState = updateHelper(state, {
        histories: {
          ...(index >= 0 && {
            $splice: [[index, 1, shop]],
          }),
          ...(index === -1 && { $unshift: [shop] }),
        },
      });
      return nextState;
    },
  },
});
const {
  actions: { update, pushHistory, updateTimeline },
} = reducer;
export default reducer;
export { update, pushHistory, updateTimeline };
