export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
export const APP_SHA = process.env.REACT_APP_SHA || Date.now();

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME;

export const SSO_LOGIN = process.env.REACT_APP_SSO_LOGIN;

export const TOKEN_CACHE_KEY = process.env.REACT_APP_TOKEN_CACHE_KEY;
export const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME;
export const NEXT_AUTH_HEADER = process.env.REACT_APP_NEXT_AUTH_HEADER;
export const NEXT_AUTH_PREFIX = process.env.REACT_APP_NEXT_AUTH_PREFIX;
export const FLASHCARD_API_ENDPOINT = process.env.REACT_APP_FLASHCARD_API_ENDPOINT;
export const PRISM_API_ENDPOINT = process.env.REACT_APP_PRISM_API_ENDPOINT;
export const ASSISTANT_API_ENDPOINT = process.env.REACT_APP_ASSISTANT_API_ENDPOINT;
export const REDMINE_API_ENDPOINT = process.env.REACT_APP_REDMINE_API_ENDPOINT;
export const ACTION = `${process.env.REACT_APP_PRISM_API_ENDPOINT}/uploads/image`;
export const UPLOAD_IMAGE_AUTH_HEADER_NAME = process.env.REACT_APP_UPLOAD_IMAGE_AUTH_HEADER_NAME;

export const IMAGE_BUCKET = process.env.REACT_APP_UPLOAD_IMAGE_BUCKET
export const WEBSITE_TITLE_TEMPLATE = `%s - ${WEBSITE_NAME}`;
export const DICE_CLIENT_ISSUE = 14;
