import { createReducer, fluxStandardActionHandleEnhancer } from 're-reducer';
import updateHelper from 'immutability-helper';

import { namespace, initialState } from './selectors';

const reducer = createReducer({
  namespace,
  initialState,
  handles: {
    update: fluxStandardActionHandleEnhancer((state, action) => {
      const {
        payload: { username, token },
      } = action;

      const nextState = updateHelper(state, {
        $merge: {
          username,
          token,
          authorized: !!token,
          pending: state.pending,
        },
      });

      return nextState;
    }),
    pend: fluxStandardActionHandleEnhancer((state, action) => {
      const { payload: pending } = action;

      const nextState = {
        ...state,
        pending,
      };

      return nextState;
    }),
  },
});

const {
  actions: { update, pend },
} = reducer;

export default reducer;
export { update, pend };
