import historiesCache from 'services/cache/histories';

const histories = historiesCache.read() || {};

export const namespace = 'timeline';
export const initialState = {
  results: [],
  timeline: [],
  histories: histories.entities || [],
  pending: false,
};

export function resultsSelector(state) {
  return state[namespace].results;
}
export function timelineSelector(state) {
  return state[namespace].timeline;
}
export function historiesSelector(state) {
  return state[namespace].histories;
}
export function pendingSelector(state) {
  return state[namespace].pending;
}

export function multipleIdsSelector(state) {
  return state[namespace].multipleIds;
}
