import prismRequest from 'services/api/prism';
import store from 'services/store';
import { tokenSelector } from 'services/store/profile/selectors';

/**
 * @typedef {import('@leyan/x-request').XContext} XContext
 */

/**
 * @typedef {import('@leyan/x-request').XMiddleware} XMiddleware
 */

/**
 * @param {string | null | (context: XContext) => string | null} token
 * @param {{
 *  header?: string;
 *  prefix?: string;
 * }} [options]
 * @returns {XMiddleware}
 */
function AuthMiddleware(token, options = {}) {
  const { header = 'Authorization', prefix = 'Bearer ' } = options;

  return async (context, next) => {
    const current = typeof token === 'function' ? token(context) : token;

    if (current) {
      context.request.headers = {
        ...context.request.headers,
        [header]: `${prefix}${current}`,
      };
    }

    await next();
  };
}

const authMiddleware = AuthMiddleware(
  () => {
    return tokenSelector(store.getState());
  },
  {
    header: process.env.REACT_APP_AUTH_HEADER_NAME,
    prefix: '',
  },
);

prismRequest.provide({
  baseURL: process.env.REACT_APP_PRISM_API_ENDPOINT,
  middlewares: [authMiddleware],
});
