import { createSelector } from 'reselect';

export const namespace = 'entities';
export const initialState = {};

export function createEntitiesSelector(type) {
  return (state) => {
    return state[namespace][type];
  };
}
export function createEntitySelector(entitiesSelector) {
  return (idSelector) => {
    return createSelector(entitiesSelector, idSelector, (entities, id) => {
      const { [id]: entity } = entities || {};

      return {
        ...entity,
        isExists: !!entity,
      };
    });
  };
}

export const timelineEntitiesSelector = createEntitiesSelector('timeline');
export const createTimelineEntitySelector = createEntitySelector(timelineEntitiesSelector);
export const tradeEntitiesSelector = createEntitiesSelector('trades');
export const createTradeEntitySelector = createEntitySelector(tradeEntitiesSelector);
export const itemEntitiesSelector = createEntitiesSelector('items');
export const createItemEntitySelector = createEntitySelector(itemEntitiesSelector);
