import compose from '@leyan/lyjs/dist/es/fp/compose';
import createRestfulApi from '@leyan/lyjs/dist/es/request/createRestfulApi';
import { headerAuth } from '@leyan/lyjs/dist/es/request/HeaderAuth';

import { NEXT_AUTH_HEADER, FLASHCARD_API_ENDPOINT } from 'services/constants';

function responseErrorInterceptor(res) {
  const { data: { code, error } = {} } = res;

  if (code && (code < 200 || code > 299)) {
    const err = new Error(error);

    Object.assign(err, res, {
      status: code,
    });

    return Promise.reject(err);
  }

  return res;
}

const Flashcard = compose(headerAuth(NEXT_AUTH_HEADER), createRestfulApi)(
  {
    baseURL: FLASHCARD_API_ENDPOINT,
  },
  {
    response: [responseErrorInterceptor],
  },
);

export default Flashcard;
