import { NEXT_AUTH_PREFIX } from 'services/constants';

import Prism from './Prism';
import Flashcard from './Flashcard';
import Assistant from './Assistant';

export default function authorize(token) {
  Prism.authorize(token);
  Flashcard.authorize(`${NEXT_AUTH_PREFIX} ${token}`);
  Assistant.authorize(`${NEXT_AUTH_PREFIX} ${token}`);
}
