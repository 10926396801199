
import { loggerManager, DebugLogger, locationContext } from '@leyan/logger';

// `debug` 实现的具备 `location` 上下文全局日志实例
const rootLogger = new DebugLogger(process.env.REACT_APP_NAME || 'app', locationContext);

// 设置全局日志输出实现
loggerManager.setLogger(rootLogger);

// 在开发和测试部署环境默认启用日志输出
if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV !== 'production') {
  rootLogger.enable();
}

const logger = loggerManager.getLogger();

export default logger;
