import { decode } from 'jsonwebtoken';

import authorizeRequest from 'services/request';
import tokenCache from 'services/cache/token';
import { update, pend } from '.';
import { tokenSelector, authorizedSelector } from './selectors';

export function unauthorize() {
  return (dispatch) => {
    dispatch(pend(true));
    dispatch(
      update({
        username: null,
        token: null,
      }),
    );

    tokenCache.destroy();

    dispatch(pend(false));
  };
}

export function authorize(token, callback) {
  return (dispatch, getState) => {
    dispatch(pend(true));
    if (!token) {
      dispatch(unauthorize());
      return;
    }

    const state = getState();
    const prevToken = tokenSelector(state);
    const authorized = authorizedSelector(state);

    if (token === prevToken && authorized) {
      return;
    }

    return new Promise((resolve) => {
      const { user: { username, realname, nickname } = {}, exp } = decode(token);

      resolve({
        username: `${nickname || realname}(${username})` ?? null,
        exp,
      });
    })
      .then((data) => {
        const { username, exp } = data;

        if (exp * 1e3 < Date.now()) {
          dispatch(unauthorize());

          return;
        }

        const profile = {
          username,
          token,
        };

        dispatch(update(profile));
        tokenCache.write(token);
        authorizeRequest(token);

        if (callback) {
          callback(undefined, profile);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }

        dispatch(update.error(err));
      })
      .finally(() => {
        dispatch(pend(false));
      });
  };
}
