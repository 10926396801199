import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// eslint-disable-next-line import/no-cycle
import errorHandle from './middlewares/errorHandle';

const middlewares = [thunk, errorHandle];
const enhancers = [];

if (process.env.NODE_ENV !== 'production') {
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const enhancer = compose(applyMiddleware(...middlewares), ...enhancers);

export default enhancer;
